import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import menuImg from './../../images/menu-svgrepo-com.svg'
import moreImg from './../../images/more.svg';
import Video from './../../video/Flex.mp4';
import Underline from './../../images/underline.png';

import NavTab from '../NavTab/NavTab';

function MainPage() {

    //Анимация кнопки далее
    function animatedMore() {
        document.querySelector('.mainPage__more').classList.add('mainPage__more-show');
        setTimeout(() => { document.querySelector('.mainPage__more').classList.remove('mainPage__more-show'); }, 1000)
    }

    //отправить сообщение 
    function handleClickMessage(message) {
        const phoneNumber = '+79995655605';
        const url = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const interval = setInterval(animatedMore, 2000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    //Открыть NavTab
    function openNavTab() {  //свернуть попап
        document.querySelector('.navtab').classList.add('navtab_visible')
    }

    //Свернуть
    function closeNavTab() {  //свернуть попап
        document.querySelector('.navtab').classList.remove('navtab_visible')
    }

    //Скролл
    function scroll() {
        document.querySelector('.mainPage__container-info').scrollIntoView({ behavior: 'smooth' });
    }

    //Клик для перемещения на инф о нас
    function handleClickAboutUs() {
        closeNavTab();
        document.querySelector('.mainPage__container-info').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    //Клик для перемещения на контакты
    function handleClickContacts() {
        closeNavTab();
        document.querySelector('.mainPage__footer').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    return (
        <main className='mainPage'>
            {/* Главная презентация */}
            {/* ВИДЕО */}
            <section className='mainPage__container'>
                <video className='mainPage__video' muted autoPlay playsInline loop src={Video} type="video/mp4"/>
                <img className='mainPage__menu' src={menuImg} alt='menuImg' onClick={openNavTab} />
                <img className='mainPage__more' src={moreImg} alt='moreImg' onClick={scroll} />
            </section>
            {/* ИНФО */}
            <section className='mainPage__container-info' id='mainPage__container-info'>
                <h3 className='mainPage__info-subtitle'>Твоя атмосферная студия растяжки в центре Екатеринбурга на 24 этаже</h3>
                <div className='mainPage__training'>
                    <p className='mainPage__info'>Пробное занятие 400р *</p>
                    <img className='mainPage__underline' src={Underline} alt='Underline' />
                </div>
                <p className='mainPage__under-button'>Присоединиться можно даже если никогда раньше не занимались растяжкой</p>
                <div className='mainPage__container-button'>
                    <button className='mainPage__button' onClick={() => handleClickMessage('Добрый день, прошу уточнить по пробной тренировке в FlexLab')}>Записаться</button>
                </div>
                <p className='mainPage__additional'>*Пробное занятие - первое занятие в студии на групповой тренировке</p>
            </section>
            <section className='mainPage__groupe'>
                <p className='mainPage__groupe-title' id='groupe-info'>Групповые и персональные тренировки:</p>
                <ul className='mainPage__list'>
                    <li className='mainPage__groupe-list'>стретчинг</li>
                    <li className='mainPage__groupe-list'>растяжка с нуля</li>
                    <li className='mainPage__groupe-list'>растяжка на шпагат</li>
                    <li className='mainPage__groupe-list'>здоровая спина</li>
                    <li className='mainPage__groupe-list'>йога в гамаках</li>
                    <li className='mainPage__groupe-list'>силовые тренировки</li>
                </ul>
            </section>
            <NavTab
                handleClickAboutUs={handleClickAboutUs}
                handleClickContacts={handleClickContacts}
            />
            {/* Футер */}
            <footer className='mainPage__footer'>
                <h3 className='mainPage__subtitle' id='contacts'>Контакты</h3>
                <div className='mainPage__footer-container'>
                    <button className='mainPage__footer-button' onClick={() => handleClickMessage('Добрый день, ')}>Связаться с нами</button>
                    <p className='mainPage__footer-info'>Соцсети: @flexlab_</p>
                    <p className='mainPage__footer-info'>Екатеринбург, БЦ Высоцкий</p>
                    <Link className='mainPage__footer-link' to='/privacy'>Политика конфиденциальности</Link>
                </div>
            </footer>
        </main>
    )
}

export default MainPage;