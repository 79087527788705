import { Route, Routes } from "react-router-dom";

import MainPage from "../MainPage/MainPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import PrivacyPage from "../PrivacyPage/PrivacyPage";

function App() {
    return (
        <main className="app">
            <Routes>
                {/* Главная страница */}
                <Route path="/" element={<MainPage />} />
                {/* Политика конфиденциальности страница */}
                <Route path="/privacy" element={<PrivacyPage />} />
                {/* Not found page */}
                <Route path="/*" element={<NotFoundPage />} />
            </Routes>
        </main>
    );
}

export default App; 